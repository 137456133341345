@import "inc/variables";
@import "inc/fonts";
@import "inc/bootstrap/critical";

@import "inc/editor";
@import "inc/js-helpers";
@import "inc/ckstyles";
@import "inc/overrides";

body
{
    text-rendering: optimizeLegibility;
    font-weight: 300;
}

p
{
    font-size: inherit;
    margin-bottom: 1em;
    line-height: 2em;
}

@import "parts/skiplinks";
@import "parts/header";
@import "parts/slider";
@import "parts/content";
@import "parts/maps";
@import "parts/video-block";
@import "parts/usps";
@import "parts/errors";
@import "parts/blog";
@import "parts/accreditations";