.ck-content
{
    word-break: break-word;

    .ck-grid-row
    {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
        grid-gap: 2rem;
        margin-bottom: 1rem;

        @media (max-width: 991.99px)
        {
            grid-gap: 1rem;
        }
        /* IE11 HACK */
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        {
            display: flex;
            flex-wrap: wrap;

            > *
            {
                margin-right: 1rem;
                margin-bottom: 1rem;
                min-width: 11rem;
                flex: 1 0 11rem;

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }

    .image-left
    {
        float: left;
        margin: 0 1rem 1rem 0;

        @media (max-width: 767.99px)
        {
            float: none;
            display: block;
            margin: 0 auto 1rem;
        }
    }

    .image-right
    {
        float: right;
        margin: 0 0 1rem 1rem;

        @media (max-width: 767.99px)
        {
            float: none;
            display: block;
            margin: 0 auto 1rem;
        }
    }

    .image-left,
    .image-right
    {
        max-width: 100%;
        object-fit: cover;
    }

    .image-center
    {
        max-width: 100%;
        text-align: center;
    }

    img
    {
        max-width: 100%;
        height: auto;
    }

    iframe
    {
        max-width: 100%;
    }

    .oembed-provider-youtube[data-align=none]
    {
        padding-bottom: 56.25%;
        width: 100%;
        margin-bottom: 1rem;
        position: relative;

        iframe
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    h3
    {
        font-size: 1.625em;
        color: $colorAccent;
        text-transform: none;
        letter-spacing: 0;
        font-weight: bold;
        margin-bottom: .25rem;
        padding-bottom: 0;
        border-bottom: 0;
        line-height: 1.5;
    }

    ul, ol
    {
        margin-bottom: 2rem;
    }

    li
    {
        font-size: 1.1rem;
    }

    @media (max-width: 768px)
    {
        h1, h2
        {
            font-size: 1.5rem;
        }

        h3
        {
            font-size: 1.333rem;
        }
    }

    .table > table
    {
        width: 100%;
    }

    details
    {
        padding: 0 1rem 1rem;
        border-bottom: 1px solid $colorBaseLight;
        margin-bottom: 1rem;

        > summary
        {
            font-weight: bold;
            color: $colorAccent;
            list-style: none;
            font-size: 1.25rem;
            outline: none !important;
            padding-right: 1.75rem;
            position: relative;
            line-height: 1.5;

            &:after
            {
                content: "\f107";
                @include line-awesome();
                position: absolute;
                right: 0;
                top: 0;
            }

            &::-webkit-details-marker
            {
                display: none;
            }
        }

        &[open] > summary:after
        {
            content: "\f106";
        }
    }

    blockquote
    {
        text-align: right;
        font-style: italic;
        font-size: 1.25rem;
        line-height: 1.333;
        color: $colorBaseLight;
        padding-left: 6rem;
        position: relative;

        &:before
        {
            content: "\f10d";
            color: $colorAccent;
            font-style: normal;
            @include line-awesome();
            position: absolute;
            left: 0;
            top: 0;
            font-size: 4rem;
            width: 6rem;
            text-align: left;
            line-height: 0.75;
        }

        > p:last-of-type
        {
            margin-bottom: 0;
        }

        > cite
        {
            color: $colorAccent;
            font-size: .75rem;
            font-style: normal;
            @include uppercaseText();
        }
    }

    .ck-rm-wrapper
    {
        margin-bottom: 1em;

        > .ck-rm-content,
        > .ck-rm-button > .ck-rm-button-open,
        &.open > .ck-rm-button > .ck-rm-button-closed
        {
            display: none;
        }

        &.open > .ck-rm-content
        {
            display: block
        }

        &.open > .ck-rm-button > .ck-rm-button-open
        {
            display: inline;
        }

        > .ck-rm-button
        {
            background-color: $colorAccent;
            color: $colorInverse;
            font-size: .75rem;
            font-style: normal;
            border: 0;
            padding: 1em 4.5em 1em 1.5em;
            line-height: 1;
            position: relative;
            font-weight: bold;
            font-family: $altFont;
            letter-spacing: 1px;
            @include uppercaseText();

            &:after
            {
                content: "\f063";
                @include line-awesome();
                display: block;
                padding: 1em;
                background-color: $colorAlternate;
                position: absolute;
                right: 0;
                top: 0;
                box-sizing: content-box;
            }
        }

        &.open > .ck-rm-button
        {
            margin-top: .75rem;

            &:after
            {
                content: "\f062";
            }
        }
    }

    .ck-tab-block .nav-link
    {
        cursor: pointer;
    }

    .ck-cta-wrapper
    {
        display: inline-block;
        max-width: 100%;

        @media (max-width: 767.99px)
        {
            display: block;
        }
    }


    .ck-cta-button
    {
        @include arrowButton();

        @media (max-width: 767.99px)
        {
            display: block;
        }
    }
}
