aside#home-slider
{
    .bx-wrapper
    {
        margin-bottom: 0;
    }

    .home-slide
    {
        height: 500px;
        color: #fff;
        background-color: $colorAccent;
        text-decoration: none;
        position: relative;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        @media(max-width: 639px)
        {
            height: 300px;
        }

        &.home-slide-1
        {
            background-image: url('/site/img/sliders/machining.jpg');
        }

        &.home-slide-2
        {
            background-image: url('/site/img/sliders/refurbishment.jpg');
        }

        &.home-slide-3
        {
            background-image: url('/site/img/sliders/testing.jpg');
        }

        .slide-text
        {
            text-decoration: none;
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 40px 50px;
            background-color: rgba($colorAccent, 0.9);
            text-align: right;
            width: 30vw;
            max-width: 100%;
            min-width: 580px;

            &:before
            {
                content: "";
                height: 100%;
                width: 100px;
                position: absolute;
                right: 100%;
                bottom: -1px;
                background-image: url('/site/img/sliders/triangle.svg');
                background-size: 100% 100%;
                display: block;
            }

            .slide-read-more
            {
                font-size: 12px;
                font-family: $altFont;
                font-weight: bold;
                text-transform: uppercase;
                background: transparent;
                border: 2px solid #fff;
                display: inline-block;
                padding: 0.5em 1.5em;
            }

            h3
            {
                font-family: $altFont;
                text-transform: uppercase;
                letter-spacing: 5px;
                font-size: 36px;
                line-height: 1;
                color: #fff;
                margin-top: 0;

                &:after
                {
                    content: "\f101";
                    font-family: 'FontAwesome';
                    font-size: 1.2em;
                    line-height: 1;
                    margin-left: 0.25em;
                    color: white;
                }
            }

            p
            {
                font-size: 18px;
                line-height: 1.33em;
                font-weight: 300;
                letter-spacing: 1.5px;
                margin-bottom: 1.1em;
            }

            @media(max-width: 639px)
            {
                width: 100%;
                max-width: 100%;
                min-width: 0;
                padding: 20px;

                &:before
                {
                    display: none;
                }

                h3
                {
                    font-size: 24px;
                    letter-spacing: 2px;
                }

                p
                {
                    font-size: 16px;
                }
            }

            @media(min-width: 640px)
            {
                .slide-text-border
                {
                    border-right: 2px solid #fff;
                    padding-right: 50px;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

aside#inner-slide
{
    background-color: $colorAccent;
    height: 300px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 1000px;

    @media (max-width: 1000px)
    {
        background-size: cover;
    }

    h1, h2
    {
        font-family: $altFont;
        color: #fff;
        letter-spacing: 5px;
        font-size: 2.333em;
        line-height: 0.9em;
        position: absolute;
        bottom: 50%;
        -ms-transform: translateY(50%);
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        margin: 0;
        border-left: 4px solid #fff;
        padding: 4px 0 4px 40px;
        max-width: 40%;

        @media(max-width: 1199px)
        {
            font-size: 2.3333em;
        }

        @media(min-width: 1200px)
        {
            max-width: 550px;
        }

        @media(max-width:991px)
        {
            max-width: 100%;
        }

        @media(max-width: 639px)
        {
            font-size: 1.8em;
            letter-spacing: 3px;
            padding: 4px 0 4px 0;
            border-left: 0;
            text-align: center;
            width: calc(100% - 30px);
            max-width: none;
        }
    }

    @media(max-width: 991px)
    {
        > div#inner-slide-overlay
        {
            position: absolute;
            background-color: rgba($colorAccent,.9);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    @media(min-width: 992px)
    {
        > div#inner-slide-overlay
        {
            position: absolute;
            background-color: $colorAccent;
            height: 1100%;
            width: 1100%;
            bottom: 20px;
            left: -545%;
            transform-origin: 54% 100%;
            transform: rotate(-70deg);
            z-index: 0;
        }
    }

    > img
    {
        position: absolute;
        top: 0;
        right: 0;
        width: 55%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;

        @media(max-width: 991px)
        {
            width: 100%;
        }
    }
}
