
article#page-content
{
    margin: 20px 0 0;
}

section#home-content,
section#page-content-main
{
    margin-bottom: 50px;
}

section#page-content-main
{
    margin-top: 1.5em;
}


.content-column
{
    margin-top: 31px;
    margin-bottom: 31px;
}

.timeline
{
    h3
    {
        margin: 0 0 .5rem;
    }

    p:last-of-type
    {
        margin-bottom: 0;
    }
}

.contact-container
{
    margin-bottom: 3em;

    h3
    {
        border-top: 3px solid $colorAccent;
        padding-top: 1em;
    }

    aside#page-callback
    {
        padding-top: 2rem;

        h3
        {
            display: none;
        }
    }

    i
    {
        color: $colorAccent;
    }

    a
    {
        text-decoration: none;
        color: $colorBaseMid;
    }
}

.address-block
{
    display: flex;

    > i
    {
        width: 2.25rem;
        font-size: 1.33em;
        margin-top: .1667rem;
    }

    > .address-content
    {
        flex: 1 0 auto;
    }
}

.contact-text
{
    @media (min-width: 768px) and (max-width: 991px)
    {
        font-size: 1rem;
    }

    @media (max-width: 767px)
    {
        margin-bottom: 2rem;
    }
}