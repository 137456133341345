.google-map,
[map-element]
{
    width: 100%;
    height: 500px;
    box-sizing: content-box;

    *
    {
        box-sizing: content-box;
    }

    @media (max-width: 767px)
    {
        margin-bottom: 2rem;
    }
}

#google-map-error
{
    display: none;
}

#google-map-form
{
    margin-top: 1em;

    label
    {
        display: none;
    }

    .form-control
    {
        border-radius: 0;
        border: 2px solid $colorAccent;
        border-right: 0;
        font-size: 1rem;
    }

    .btn.btn-dark
    {
        background-color: #fff;
        color: $colorBaseMid;
        border: 2px solid $colorAccent;
        border-radius: 0;
        border-left: 0;
    }
}

#google-map-branch-list > ul
{
    list-style-type: none;
    padding: 0;

    > li > a
    {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        padding: 4px 0;
        color: $colorAccent;
        display: block;
        width: 140px;
        position: relative;

        &:after
        {
            content: "\f101";
            @include line-awesome();
            position: absolute;
            right: 0;
            color: #6da2d6;
        }
    }
}

.google-map-link
{
    color: $colorAccent;
    font-weight: 300;
    font-size: 14px;
}

[map-directions]
{
    margin-top: 1em;
}

.adp-placemark td
{
    padding: 4px;
}

.adp-marker
{
    width: auto !important;
    height: 24px !important;
}

.adp-fullwidth td
{
    padding: 2px 4px;
}

.adp-directions
{
    table-layout: fixed;
    width: 100%;

    td:nth-child(1),
    td:nth-child(2)
    {
        width: 5% !important;
    }

    td:nth-child(4)
    {
        width: 10% !important;
    }
}

.adp-substep
{
    word-wrap: break-word;
}

@include media-breakpoint-down(sm)
{
    #google-map-from
    {
        margin-bottom: 10px;
    }
}

.gmaps-info-window
{
    padding: 1rem;

    > h4
    {
        letter-spacing: normal;
        font-size: 1rem;
    }
}
