.news-item,
.latest-news-item
{
    .list-inline
    {
        margin: .5rem 0 1rem;

        a
        {
            border: 2px solid currentColor;
            text-transform: uppercase;
            text-decoration: none;
            font-size: .75rem;
            line-height: 1;
            font-weight: bold;
            padding: .333rem 1.667rem;
            display: block;
            background-color: #fff;
        }

        @include media-breakpoint-up(lg)
        {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.news-item.news-archive
{
    border: 2px solid $colorAccent;
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 7fr 1fr;
    margin: 0;

    .news-image
    {
        align-self: center;
        height: 100%;

        img
        {
            object-fit: cover;
            width: 100%;
            height: 100%;
            display: block;
        }

        img.promo-block-placeholder
        {
            object-fit: contain;
            padding-left: 3rem;

            @include media-breakpoint-down(lg)
            {
                padding-right: 3rem;
            }
        }
    }

    @include media-breakpoint-down(lg)
    {
        grid-template-columns: 1fr;

        img
        {
            aspect-ratio: 16 / 9;
        }

        .news-icon
        {
            display: none;
        }
    }

    .news-content
    {
        padding: 1.5rem;

        h3
        {
            margin: 0 0 .5rem;
            text-transform: none;
            letter-spacing: normal;

            > a
            {
                text-decoration: none;
            }
        }

        ul
        {
            margin-top: 0;
        }

        p
        {
            line-height: 1.5;
        }

        li.list-inline-item
        {
            margin-bottom: .333rem;
        }

        @include media-breakpoint-down(md)
        {
            padding: 2rem;
        }
    }

    .news-icon
    {
        a
        {
            text-decoration: none;
        }

        i
        {
            font-size: 4rem;
            color: $colorAlternate;
            display: block;
            text-align: center;
            margin-right: 1rem;
        }
    }
}


.news-item.news-single
{
    margin-bottom: 3em;

    .news-header
    {
        margin-bottom: 1.5rem;

        h1
        {
            text-transform: none;
            letter-spacing: 0;
            font-size: 2.5rem;
            margin: 0;

            @media (max-width: 767px)
            {
                font-size: 2rem;
            }
        }

        i
        {
            font-size: 3.5em;
            color: $colorAccent;

            @media (max-width: 767px)
            {
                font-size: 2em;
            }
        }

        .news-content-header
        {
            display: flex;
            gap: 1rem;
        }

        p
        {
            font-size: 0.83333rem;
            font-weight: bold;
        }
    }
}

aside.latest-news-block
{
    padding: 1em 0 3em;

    h3
    {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 42px;

        > span.strike-clear
        {
            background-color: #fff;
        }
    }

    .view-all-link
    {
        text-align: right;
        margin-top: 1.5em;
    }
}

.latest-news-item
{
    border: 2px solid $colorAccent;


    h4
    {
        position: relative;

        > a
        {
            display: block;
            text-transform: none;
            letter-spacing: normal;
            padding: 0 4rem 1rem 1rem;
        }
    }

    a
    {
        text-decoration: none;
    }

    .list-inline
    {
        position: relative;
        margin-bottom: 0;
        margin-top: 0;
        transform: translateY(-50%);
        display: flex;
        padding: 0 1em;
        overflow: hidden;
        flex-wrap: wrap;
        height: 1.6em;
        justify-content: center;
    }

    .news-icon i
    {
        font-size: 4rem;
        color: $colorAlternate;
        display: block;
        text-align: center;
        margin-right: 1rem;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(calc(-50% - .5rem));
        pointer-events: none;
    }
}

#news-category-list
{
    h3
    {
        color: $colorAccent;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1.5em;
        border-top: 2px solid $colorAccent;
        padding-top: 1em;

        @media(max-width: 767px)
        {
            margin-top: 2.5em;
        }
    }

    .news-category-dropdown
    {
        display: flex;
        flex-direction: column;
        height: 2.5rem;
        overflow: hidden;
        border: 2px solid $colorAccent;
        position: relative;
        background-color: #fff;
        max-width: 320px;
        margin: 0 auto;

        > a
        {
            text-align: left;
            text-transform: uppercase;
            padding: 0.375rem 0.75rem;
            text-decoration: none;
            border-top: 1px solid $colorAccent;
            font-size: 1rem;

            &.current-item
            {
                order: -1;
                margin-right: 3rem;
                border-top: 0;
                pointer-events: none;
            }
        }

        &:after
        {
            position: absolute;
            @include line-awesome();
            content: "\f107";
            right: 0;
            top: 0;
            padding: 0.375rem 0.75rem 0.375rem 0.75rem;
            text-align: center;
            color: $colorAccent;
            font-size: 1rem;
        }

        &.active
        {
            height: auto;

            &:after
            {
                content: "\f106";
            }
        }
    }
}
