aside#home-points
{
    background-color: $colorAlternate;

    @media (max-width: 767px)
    {
        padding: 1.5em 0;

        .points-tabs li.col-sm-3.active
        {
            display: block;
        }
    }

    ul
    {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    svg
    {
        fill: #fff;
    }


    .points-tabs
    {
        .active
        {
            color: $highlight;

            svg
            {
                fill: $highlight;
            }

            a.points-item
            {
                color: $highlight;
            }

            &:after
            {
                content: "";
                position: absolute;
                top: 100%;
                left: calc(50% - 6px);
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid $colorAlternate;
            }
        }
    }

    .points-item
    {
        color: #fff;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        padding: 3em 0;
        text-align: center;

        @media (max-width: 767px)
        {
            padding: 1.5em 0;
        }

        > .points-item-icon
        {
            width: 140px;
            height: 75px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg
            {
                max-height: 75px;
            }

            @media (min-width: 992px) and (max-width: 1199px)
            {
                width: 48px;
            }
        }

        > .points-item-name
        {
            display: inline-block;
            vertical-align: middle;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            text-transform: uppercase;
            letter-spacing: 3px;

            @media (min-width: 768px) and (max-width: 991px)
            {
                display: block;
                margin-top: 0.5em;
                text-align: center;
                font-size: .75em;
            }
        }
    }

    > .points-content
    {
        background-color: #fff;
        padding: 4em 0;

        h3
        {
            color: $colorBaseMid;
            margin-top: 0;
        }

        @media (max-width: 767px)
        {
            text-align: center;

            img.img-fluid
            {
                max-width: 200px;
                margin: 0 auto 1em;
                display: block;
            }
        }
    }
}
