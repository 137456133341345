aside#home-video,
aside#inner-video
{
    background-image: url('/site/img/video-bg.jpg');
    background-size: cover;
    background-position: right center;
    height: 450px;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    text-decoration: none;

    @media(max-width: 640px)
    {
        height: 280px;
    }

    .video-content-play
    {
        font-size: 125px;
    }

    h3
    {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 0;

        @media(max-width: 767px)
        {
            text-align: center;
        }
    }
}

aside#home-video
{
    margin-bottom: 1em;
    text-align: center;

    > div#video-overlay
    {
        position: absolute;
        background-color: rgba($colorAccent,.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    > div.container
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    div#video-content
    {
        .video-content-play
        {
            text-align: center;

            &:after
            {
                top: calc(50% - 0.2em);
                left: calc(50% - 0.2em);
            }
        }
    }
}

.video-content-play
{
    z-index: 10;
    color: #e52d27;
    line-height: 1;
    position: relative;

    &:after
    {
        content: "";
        background-color: #fff;
        height: 0.4em;
        width: 0.4em;
        position: absolute;
        top: .3em;
        left: .3em;
        z-index: -1;
    }
}

.btn-video
{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 0.667em 1.25em;

    > span
    {
        display: inline-block;
        vertical-align: middle;
    }

    .video-content-play
    {
        font-size: 2em;
    }
}

aside#inner-video
{
    text-align: center;
    margin-bottom: -1.55em;
    height: 180px;
    background-position: right bottom;

    > div#video-overlay
    {
        position: absolute;
        background-color: rgba(51,51,139,.5);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @media (min-width: 768px)
        {
            display: none;
        }
    }

    div#video-content
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        .container-fluid
        {
            display: flex;
            gap: 2rem;
            align-items: center;
        }

        h3
        {
            margin-bottom: 0;
            font-size: 35px;
            text-shadow: 0 0 3px rgba(black, 0.75);
        }
    }

    .video-content-play
    {
        &:after
        {
            content: "";
            background-color: #fff;
            height: 0.4em;
            width: 0.4em;
            position: absolute;
            top: calc(50% - 0.2em);
            left: calc(50% - 0.2em);
            z-index: -1;
        }
    }
}
