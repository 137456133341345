.accreditation-grid
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 50px;
}

.accreditation-item
{
    border: 2px solid $colorAccent;
    padding: 2rem;

    > h4
    {
        font-size: 1.5rem;
        font-family: $altFont;
        font-weight: 300;
        color: $colorBaseMid;
        text-transform: none;
        letter-spacing: 0;
        margin: 1.5rem 0 0;

        &:first-child
        {
            margin-top: 0;
        }
    }

    > img
    {
        width: 100%;
        display: block;
    }
}
