header#page-header
{
    padding: 1.5em 0 0;
    font-family: $altFont;
    background-color: #fff;
    z-index: 100;

    img#page-header-logo
    {
        max-height: 100px;
        transition: all linear .333s;

        @media(max-width: 991px)
        {
            margin-bottom: 20px;
            display: inline-block;
        }

        @media(max-width:767px)
        {
            margin-bottom: 0;
        }
    }

    div#page-header-phone
    {
        font-size: 32px;
        line-height: 1.25em;
        font-weight: bold;

        > a
        {
            text-decoration: none;
        }

        @media(max-width: 991px)
        {
            font-size: 28px;
        }
    }

    div#page-header-email
    {
        font-size: 18px;
        line-height: 1.25em;

        > a
        {
            text-decoration: underline;
        }
    }

    @media(max-width: 767px)
    {
        text-align: center;

        .row > *
        {
            margin-top: 1rem;

            &:first-child
            {
                margin-top: 0;
            }
        }

        .desktop-right
        {
            float: none;
            text-align: left;

            @media(min-width: 600px)
            {
                text-align: center;
            }
        }

        div#page-header-phone
        {
            font-size: 30px;
        }
    }

    @media (min-width: 992px)
    {
        position: sticky;
        top: 0;
    }

    &.page-header-stuck
    {
        box-shadow: 0 0 3px rgba(black, 0.33);
        padding: 1.125em 0 0;

        img#page-header-logo
        {
            max-height: 60px;
            transition: all linear .333s;
        }

        .row > *
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        #page-navigation
        {
            margin-top: 1.125em;
        }
    }
}

#page-navigation
{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $altFont;
    letter-spacing: 1.5px;
    margin-top: 1.5rem;

    a
    {
        text-decoration: none;
    }

    .navbar-nav > li
    {
        > a
        {
            color: #000;
            border-top: 2px solid transparent;
            font-weight: normal;

            @media (min-width: 992px)
            {
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }

            @media (max-width: 991px)
            {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    @media (min-width: 992px)
    {
        .navbar-nav > li.active > a,
        .navbar-nav > li.active > a:focus,
        .navbar-nav > li.active > a:hover
        {
            color: #000;
            border-color: $colorAccent;
        }
    }

    .dropdown-menu
    {
        font-family: $altFont;
        width: 300px;
        max-width: 100vw;
        padding: 12px 0;

        @media (max-width: 991px)
        {
            width: 100%;
            border: 0;
        }
    }

    .dropdown-menu > li.dropdown-header
    {
        margin: 16px 30px 8px;
        padding: 0;
        font-size: 15px;
        line-height: 1.25em;
        font-weight: normal;
        letter-spacing: 1px;

        .strike-clear
        {
            background-color: $colorAccent;
        }

        &:after
        {
            width: calc(100% - 60px);
            border-bottom: 1px;
        }
    }

    .dropdown-menu > li > a
    {
        padding: 6px 24px;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 1px;
        color: $colorBaseMid;

        &:focus,
        &:hover
        {
            background-color: transparent;

            &:after
            {
                content: "\f101";
                font-family: "FontAwesome";
                margin-left: 2px;
                font-size: 1.1em;
                line-height: 1;
            }
        }
    }

    .navbar-toggler
    {
        position: absolute;
        right: 1rem;
        border-radius: 0;
        top: 0;
    }

    @media(max-width: 991px)
    {
        margin-top: -48px;
        padding-top: 64px;

        .navbar-toggler
        {
            margin-bottom: 1em;
            top: 12px;
        }
    }

    @media (max-width: 767px)
    {
        .navbar-toggler
        {
            top: 0;
        }
    }
}
