.btn-secondary
{
    @include button-variant($secondary, $colorBaseMid);
}


h1
{
    text-transform: uppercase;
}

h1, h2, h3, .h1, .h2, .h3
{
    margin-top: 31px;
    margin-bottom: 20px;
}

a
{
    text-decoration: underline;

    &.btn
    {
        text-decoration: none;
    }
}

table a
{
    text-decoration: none;
}

.strike-around
{
    position: relative;
    width: 100%;

    > span.strike-clear
    {
        display: inline-block;
        padding: 0 0.75em;
        margin: 0 -0.75em;
        z-index: 1;
        position: relative;
    }

    &:after
    {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(0.5em - 1px);
        border-width: 1px;
        border-style: solid;
        z-index: 0;
    }
}

.social-link
{
    font-size: 18px;
    display: inline-block;
    width: 1.9em;
    height: 1.9em;
    border: 0.15em solid;
    border-radius: 0.95em;
    margin-right: 0.5em;
    color: #fff;
    text-align: center;
    line-height: 1.7em;
    vertical-align: top;
    transition: background-color linear 200ms;

    &:hover,
    &:active,
    &:focus
    {
        color: #fff;
    }

    &:last-child
    {
        margin-right: 0;
    }

    &.social-fb
    {
        background-color: #3b5998;
        border-color: #3b5998;

        &:hover
        {
            background-color: lighten(#3b5998, 10%);
        }

        > i
        {
            margin-left: -1px;
        }
    }

    &.social-li
    {
        background-color: #0077b5;
        border-color: #0077b5;
        line-height: 1.6em;

        &:hover
        {
            background-color: lighten(#0077b5, 10%);
        }
    }
}


.tablet-left,
.tablet-center,
.tablet-right,
.desktop-left,
.desktop-center,
.desktop-right
{
    text-align: center;
}


@media (max-width: 767px)
{
    .desktop-left.pull-left,
    .desktop-right.pull-right
    {
        float: none !important;
    }
}

@media (min-width: 768px)
{
    .tablet-left
    {
        text-align: left;
    }

    .tablet-center
    {
        text-align: center;
    }

    .tablet-right
    {
        text-align: right;
    }
}

@media (min-width: 992px)
{
    .desktop-left
    {
        text-align: left;
    }

    .desktop-center
    {
        text-align: center;
    }

    .desktop-right
    {
        text-align: right;
    }
}

.container-fluid-edge > .row > div
{
    padding: 0 !important;
}